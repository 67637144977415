<template>
	<div class="test">
		<div class="page_1">
			<PageNav :show_num="4" />
			<div class="page_1_center_box">
				<div class="cen-text">
					Smart Branch Solution
				</div>
				<div class="cen-msg">
					The smart branch solution provides a customer facing self-service
					portal,
					supporting around 300 banking transactions,
					and cross-platform monitoring and
					management functions, to improve efficiency
					and customer satisfaction, help branch focus
					more on customer service and marketing
					promotion.
				</div>
			</div>
		</div>
		<div class="page_2">
			<div class="page_2_1">
				<div class="page_2_1_top">
					<div class="top-title">
						Improve efficiency and customer experience
					</div>
					<div class="bot-line">

					</div>
				</div>
				<div class="page_2_1_bot">
					<div class="page_2_left">
					</div>
					<div class="page_2_right">
						<div class="page_2_list">
							<div class="list-name">
								Minimize branch size
							</div>
							<div class="list-msg">
								Less counter, less queueing up.
							</div>
						</div>
						<div class="page_2_list">
							<div class="list-name">
								Improve human efficiency
							</div>
							<div class="list-msg">
								Automate the repeat counter work, save more time for marketing work.
							</div>
						</div>
						<div class="page_2_list">
							<div class="list-name">
								Reduce operation cost
							</div>
							<div class="list-msg">
								Reduce copy & priting device cost, paper use and storage cost ...
							</div>
						</div>
					</div>
				</div>


			</div>
		</div>
		<div class="page_3">
			<div class="page_3_1">
				<div class="page_3_1_top">
					<div class="top-title">
						Instant Card Issuance
					</div>
					<div class="bot-line">

					</div>
				</div>
				<div class="page_3_1_bot">
				</div>

			</div>
		</div>
		<div class="page_4">
			<div class="page_4_1">
				<div class="page_4_1_top">
					<div class="top-title">
						System Overview
					</div>
					<div class="bot-line">

					</div>
				</div>
				<div class="page_4_1_bot">
				</div>

			</div>
		</div>
		<div class="page_5">
			<div class="page_5_1">
				<div class="page_5_1_top">
					<div class="top-title">
						Hardware product
					</div>
					<div class="bot-line">

					</div>
				</div>
				<div class="page_5_1_bot">
					<img src="../../assets/images/soluts/solut2_4.png" alt="" />
					<img src="../../assets/images/soluts/solut2_5.png" alt="" />
					<img src="../../assets/images/soluts/solut2_6.png" alt="" />
					<img src="../../assets/images/soluts/solut2_7.png" alt="" />
				</div>

			</div>
		</div>
		<div class="page_6">
			<div class="page_6_1">
				<div class="page_6_1_top">
					<div class="top-title">
						Functions Overview
					</div>
					<div class="bot-line">

					</div>
				</div>
				<div class="page_6_1_bot">
					<div class="page_6_left">
						<img src="../../assets/images/soluts/solut2_8.png" alt="" />
						<div class="page_6_msg">
							Bank services
						</div>
					</div>
					<div class="page_6_right">
						<img src="../../assets/images/soluts/solut2_9.png" alt="" />
						<div class="page_6_msg">
							Operation and Management
						</div>
					</div>
				</div>


			</div>
		</div>
		<div class="page_7">
			<div class="page_7_1">
				<div class="page_7_1_top">
					<div class="top-title">
						Integrate 3rd party services
					</div>
					<div class="bot-line">

					</div>
				</div>
				<div class="page_7_1_bot">
				</div>

			</div>
		</div>

		<PageBot :show_num="4" />
		<el-backtop class="back_up" target="" :bottom="100" :visibility-height="620" :right="10">
			<svg class="icon icon_xiangshang" aria-hidden="true">
				<use xlink:href="#icon-xiangshang"></use>
			</svg>
		</el-backtop>
	</div>
</template>

<script>
	import PageNav from "@/components/PageNav.vue"
	import PageBot from "@/components/PageBottom.vue"
	import Swiper from "swiper";
	import "swiper/css/swiper.min.css";
	import {
		resetRem
	} from '@/all_js/all';
	import {
		clickEffect
	} from "@/all_js/click";
	export default {
		name: 'about',
		components: {
			PageBot,
			PageNav
		},
		created() {
			// resetRem(); // 根据1920尺寸设置rem，1rem=100px
			// clickEffect(); // 引入鼠标点击特效
		},
		watch: {

			screenWidth: {
				handler: function(val) {
					// console.log("当前屏幕尺寸--" + val)
					// console.log(this.list_status)
					if (val != null && val < 742) {
						this.list_status = false
					} else {
						this.list_status = true
					}
					// console.log(this.list_status)
				},
				immediate: true,
				deep: true
			},
		},

		mounted() {
			window.addEventListener("scroll", this.watchScroll); // 滚动吸顶
			this.screenWidth = document.body.clientWidth
			window.onresize = () => {
				return (() => {
					this.screenWidth = document.body.clientWidth // 监听屏幕宽度
				})()
			}

		},
		data() {
			return {
				avtive_nav: "",
				show_device_detail: false,
				device_detail: {},
				currentSwiper: null,
				currentSwipers: null,
				acctive_img: require("../../assets/images/about/box_1.png"),
				show_top: false,
				data_list: [{
						id: 0,
						img: require("../../assets/images/about/box_1.png"),
						acctive: true
					}, {
						id: 1,
						img: require("../../assets/images/about/box_2.png"),
						acctive: false
					},
					{
						id: 2,
						img: require("../../assets/images/about/box_3.png"),
						acctive: false
					},
					{
						id: 3,
						img: require("../../assets/images/about/box_4.png"),
						acctive: false
					},

				],
				flag_status: true,
				navBarFixed: false,
				screenWidth: null,
				list_status: true,
				avtive_img: 0
			}
		},
		methods: {
			// 鼠标移入-导航
			enter_img(item) {
				this.acctive_img = item.img;
				for (var i = 0; i < this.data_list.length; i++) {
					if (item.id == this.data_list[i].id) {
						this.data_list[i].acctive = true;
					} else {
						this.data_list[i].acctive = false;
					}
				}
			},
			// 滚动吸顶
			watchScroll() {
				// 当滚动超过 90 时，实现吸顶效果
				var scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				if (scrollTop > 90) {
					this.navBarFixed = true;
				} else {
					this.navBarFixed = false;
				}
			},
			go_index() {
				window.location.href = "/";
			},
			go_contact() {
				this.$router.push({
					path: '/contact',
					query: {
						id: '123456'
					},

				})
			},
		}
	}
</script>
<style scoped lang="less">
	* {
		margin: 0;
		padding: 0;
		// transition: all 1s; // 设置动画效果
	}

	li {
		list-style-type: none;
	}

	.page_1 {
		width: 100%;
		height: 100vh;
		max-height: 950px;
		object-fit: cover; // 保持图片缩放时候不变形
		background-image: url("../../assets/images/soluts/solut2_bg.png");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none; // 子元素无法被选中
	}


	.page_1_center_box {
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		color: #FFFFFF;

		.cen-text {
			margin-top: 30vh;
			margin-left: 15%;
			// background-color: aqua;
			font-size: 60px;
		}

		.cen-line {
			width: 72px;
			height: 4px;
			margin-top: 30px;
			margin-left: 15%;
			background-color: #FFFFFF;
		}

		.cen-content {
			margin-top: 10px;
			margin-left: 15%;
			font-size: 24px;
		}

		.cen-msg {
			width: 600px;
			margin-top: 10px;
			margin-left: 15%;
			font-size: 18px;
			line-height: 30px;
			// width: 500px;
		}


	}








	.back_up {
		height: 40px;
		width: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 30px;
		background-color: #49b1f5;

		.icon_xiangshang {
			height: 30px;
			width: 30px;
			border-radius: 30px;
		}
	}

	.back_up:hover {
		background-color: #49b1f5;
	}
</style>
<style scoped lang="less">
	// @media only screen and (min-width:1371px) {}

	.page_2 {
		width: 100%;
		min-width: 1360px;
		padding-top: 100px;
		padding-bottom: 100px;
		background: #FFFFFF;


		.page_2_1 {
			width: 1360px;
			margin: 0 auto;


			.page_2_1_top {
				width: 100%;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;

				.top-title {
					color: #000000;
					font-size: 48px;
				}

				.bot-line {
					margin-top: 20px;
					width: 127px;
					height: 4px;
					background-color: #0081CC;
				}

			}

			.page_2_1_bot {
				width: 94%;
				margin: 0 auto;
				margin-top: 80px;
				display: flex;
				align-items: center;
				justify-content: space-between;


				.page_2_left {
					width: 820px;
					height: 601px;
					background-image: url("../../assets/images/soluts/solut2_1.png");
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center center;

				}

				.page_2_right {
					width: 400px;
					height: 420px;
					display: flex;
					flex-direction: column;
					justify-content: space-between;

					.page_2_list {
						height: 130px;
						background-color: #F7F7F7;
						border-radius: 5px;
						display: flex;
						flex-direction: column;

						.list-name {
							font-size: 24px;
							color: #000000;
							margin: 30px 0 10px 20px;
						}

						.list-msg {
							font-size: 14px;
							color: #ACACAC;
							margin-left: 20px;
						}
					}
				}
			}
		}
	}

	.page_3 {
		background: #FAFAFA;
		padding-bottom: 100px;

		.page_3_1 {
			width: 1360px;
			margin: 0 auto;
		}

		.page_3_1_top {
			padding-top: 100px;
			width: 100%;
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;

			.top-title {
				color: #000000;
				font-size: 48px;
			}

			.bot-line {
				margin-top: 20px;
				width: 127px;
				height: 4px;
				background-color: #0081CC;
			}

		}

		.page_3_1_bot {
			width: 100%;
			height: 470px;
			margin-top: 100px;
			background-image: url("../../assets/images/soluts/solut2_2.png");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;


		}

	}

	.page_4 {
		background: #FFFFFF;
		padding-bottom: 100px;

		.page_4_1 {
			width: 1360px;
			margin: 0 auto;
		}

		.page_4_1_top {
			padding-top: 100px;
			width: 100%;
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;

			.top-title {
				color: #000000;
				font-size: 48px;
			}

			.bot-line {
				margin-top: 20px;
				width: 127px;
				height: 4px;
				background-color: #0081CC;
			}

		}

		.page_4_1_bot {
			width: 100%;
			height: 525px;
			margin-top: 100px;
			background-image: url("../../assets/images/soluts/solut2_3.png");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;


		}

	}

	.page_5 {
		background: #FFFFFF;
		height: 920px;
		background-image: url("../../assets/images/soluts/solut0_18.png");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;

		.page_5_1 {
			width: 1360px;
			margin: 0 auto;
		}

		.page_5_1_top {
			padding-top: 100px;
			width: 100%;
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;

			.top-title {
				color: #FFFFFF;
				font-size: 48px;
			}

			.bot-line {
				margin-top: 20px;
				width: 127px;
				height: 4px;
				background-color: #FFFFFF;
			}

		}

		.page_5_1_bot {
			width: 100%;
			height: 470px;
			margin-top: 80px;
			display: flex;
			justify-content: space-between;

			img {
				width: 320px;
				height: 460px;
				border-radius: 5px;
				object-fit: cover;
			}

		}

	}



	.page_6 {
		width: 100%;
		min-width: 1360px;
		padding-top: 150px;
		padding-bottom: 200px;
		background: #FFFFFF;


		.page_6_1 {
			width: 1360px;
			margin: 0 auto;

			.page_6_1_top {
				width: 100%;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;

				.top-title {
					color: #000000;
					font-size: 48px;
				}

				.bot-line {
					margin-top: 20px;
					width: 127px;
					height: 4px;
					background-color: #0081CC;
				}

			}

			.page_6_1_bot {
				width: 100%;
				margin-top: 80px;
				display: flex;
				align-items: flex-end;
				justify-content: space-between;

				.page_6_left {
					width: 635px;
					display: flex;
					flex-direction: column;
					align-items: center;

					img {
						width: 635px;
						height: 320px;
					}

					.page_6_msg {
						width: 460px;
						height: 58px;
						background-color: #F5F5F5;
						color: #000000;
						font-size: 24px;
						border-radius: 5px;
						margin-top: 42px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}

				.page_6_right {
					width: 635px;
					display: flex;
					flex-direction: column;
					align-items: center;

					img {
						width: 635px;
						height: 260px;
					}

					.page_6_msg {
						width: 460px;
						height: 58px;
						background-color: #F5F5F5;
						color: #000000;
						font-size: 24px;
						border-radius: 5px;
						margin-top: 90px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}
		}
	}

	.page_7 {
		background: #FAFAFA;
		padding-bottom: 100px;

		.page_7_1 {
			width: 1360px;
			margin: 0 auto;
		}

		.page_7_1_top {
			padding-top: 100px;
			width: 100%;
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;

			.top-title {
				color: #000000;
				font-size: 48px;
			}

			.bot-line {
				margin-top: 20px;
				width: 127px;
				height: 4px;
				background-color: #0081CC;
			}

		}

		.page_7_1_bot {
			width: 100%;
			height: 625px;
			margin-top: 100px;
			background-image: url("../../assets/images/soluts/solut2_10.png");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;


		}

	}
</style>